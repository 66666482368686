import * as React from "react"
import * as R from "ramda"
import Seo from "../../components/seo"
import { useMicroCopySv } from "../../hooks/microCopy/sv"
import GetMicroCopy from "../../shared/getMicroCopy"
import LayoutController from "../../components/layoutController"

const isBrowser = () => typeof window !== "undefined"
setTimeout(() => {
  isBrowser() && window.location.replace('https://www.lippu.fi/eventseries/3351619?affiliate=NKE')
}, 2000);

const TicketsPage = () => {
  const microCopyTexts = useMicroCopySv
  // UI texts
  const title = GetMicroCopy(microCopyTexts, "liputOtsikko")
  const lead = GetMicroCopy(microCopyTexts, "liputTeksti")

  return (
    <LayoutController language="sv"> 
      <Seo
        seoTitle={title}
        seoDesc={lead}
        url={typeof window !== "undefined" ? window.location.href : ""}
      />
      <p class="text-center">Redirecting...</p>
      <section>
        <header 
          className="bg-blue pt-10 pb-[150px] md:pb-[280px] px-5 bg-center-top md:bg-cover bg-no-repeat md:bg-right-top"
          style={{backgroundImage: `url(https://images.ctfassets.net/ksssf3t869cm/7uM33xHgHXPCAk9Bw4JZd1/ca779fa3cd577820d0e9e8bf978c6c68/Asuntomessut_naantali_heroanimaatio.gif)` }}
        >
          <div className="max-w-3xl mx-auto text-center">
            {title && <h1>{title}</h1>}
            {lead && <p>{lead}</p>}
          </div>
        </header>
        <div className="relative mt-[-150px] md:mt-[-280px] px-5">
         {/* <div dangerouslySetInnerHTML={iframe}></div> */}
        </div>
      </section>

    </LayoutController>
  )
}

export default TicketsPage
